﻿export function showLoginWindow(url, title) {

    var width = 384;
    var height = 512;
    var left = (screen.width / 2) - (width / 2);
    var top = (screen.height / 2) - (height / 2);

    var wnd = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, copyhistory=no, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);

    return new Promise(function (resolve) {

        var interval = setInterval(function () {
            try {
                if (wnd.closed) {
                    try {
                        resolve();
                    }
                    finally {
                        clearInterval(interval);
                    }
                }
            }
            catch (ex) {
                console.log("[DETRAV] error on sign in window close, " + ex);
                clearInterval(interval);
            }
        }, 100);

    })

}