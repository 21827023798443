﻿
var toBase64 = function (file) {

    console.log("file", file);
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () { resolve(reader.result) };
        reader.onerror = function (error) { reject(error) };
    })
}

export function getInputFiles(element) {

    var fileList = Array.prototype.map.call(element.files, async function (file) {
        var result = {
            lastModified: new Date(file.lastModified).toISOString(),
            name: file.name,
            size: file.size,
            type: file.type,
            relativePath: file.webkitRelativePath,
            base64string: await file.arrayBuffer()
        };

        // Attach the blob data itself as a non-enumerable property so it doesn't appear in the JSON
        //Object.defineProperty(result, 'blob', { value: file });

        return result;
    });

    return Promise.all(fileList);
}