﻿import $ from 'jquery';

export function clickSideBar() {
    if ($('.fr-cloud-sidebar').hasClass('fr-cloud-sidebar-small')) {
        $(".fr-cloud-sidebar").removeClass("fr-cloud-sidebar-small");
        $(".fr-cloud-main").removeClass("fr-cloud-main-full");

        // removes a tooltip from every sidebar item
        let $parents = $('.nav-link');
        $parents.each(
            function () {
                var $el = $(this);
                var title = $el.find('.nav-link-title').text();
                if (title) {
                    $el.removeAttr("data-placement");
                    $el.removeAttr("title");
                    $el.removeAttr("data-toogle");
                }
            }
        );
    }
    else {
        $(".fr-cloud-sidebar").addClass("fr-cloud-sidebar-small");
        $(".fr-cloud-main").addClass("fr-cloud-main-full");

        // adds a tooltip to every sidebar item
        let $parents = $('.nav-link');
        $parents.each(
            function () {
                var $el = $(this);
                var title = $el.find('.nav-link-title').text();
                if (title) {
                    $el.attr("data-placement", "right");
                    $el.attr("title", title);
                    $el.attr("data-toogle", "tooltip");
                }
            }
        );
    }
}

export function hideSideBar() {
    $(".fr-cloud-sidebar").addClass("fr-cloud-sidebar-no");
    $(".fr-cloud-main").addClass("fr-cloud-main-no-sidebar");
}

export function showSideBar() {
    $(".fr-cloud-sidebar").removeClass("fr-cloud-sidebar-no");
    $(".fr-cloud-main").removeClass("fr-cloud-main-no-sidebar");
}