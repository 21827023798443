﻿import $ from 'jquery';

export function flip(el, currentValue, val) {
    var top = el.querySelector('.card__top'),
        bottom = el.querySelector('.card__bottom'),
        back = el.querySelector('.card__back'),
        backBottom = el.querySelector('.card__back .card__bottom');

    back.setAttribute('data-value', currentValue);
    bottom.setAttribute('data-value', currentValue);
    currentValue = val;
    top.innerText = currentValue;
    backBottom.setAttribute('data-value', currentValue);

    el.classList.remove('flip');
    void el.offsetWidth;
    el.classList.add('flip');
}


