﻿import $ from 'jquery';

export function hideScreenLoading() {
    $('.fr-cloud-screen-loading').remove();
}

export function appendHead(head) {
    $('head').append(head);
}

export function appendBody(body) {
    $('body').append(body);
}