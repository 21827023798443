﻿window.outsideClickHandler = {
    addEvent: function (elementId, dotnetHelper) {
        window.addEventListener("click", (e) => {
            let element = document.getElementById(elementId);
            if (element == null) return;
            if (!element.contains(e.target)) {
                dotnetHelper.invokeMethodAsync("InvokeClickOutside");
            }
        });
    }
};