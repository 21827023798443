﻿import $ from 'jquery';

export function clickSideBar() {

    if ($('.fr-cloud-sidebar').hasClass('fr-cloud-sidebar-small')) {
        $(".fr-cloud-sidebar").removeClass("fr-cloud-sidebar-small");
        $(".fr-cloud-main").removeClass("fr-cloud-main-full");
    }
    else {
        $(".fr-cloud-sidebar").addClass("fr-cloud-sidebar-small");
        $(".fr-cloud-main").addClass("fr-cloud-main-full");
    }

}
