export function enableCopyInviteBox(createInviteBox, copyInviteBox) {
    document.getElementById(createInviteBox).setAttribute("hidden", "");
    document.getElementById(copyInviteBox).removeAttribute("hidden", "");
}

export function enableCreateInviteBox(createInviteBox, copyInviteBox) {
    document.getElementById(createInviteBox).removeAttribute("hidden", "");
    document.getElementById(copyInviteBox).setAttribute("hidden", "");
}

export function setInput(inputId, value) {
    document.getElementById(inputId).value = value;
}