﻿import $ from 'jquery';

export function init(objDom, objDotNet) {

    $(objDom).on('shown.bs.modal', async function (e) {
        await objDotNet.invokeMethodAsync('onShown');
    })

    $(objDom).on('hide.bs.modal', function (e) {
        let close = objDotNet.invokeMethod('onHide');
        if (!close) {
            e.preventDefault();
            e.stopImmediatePropagation();
            return false;
        }
    })

    $(objDom).on('hidden.bs.modal', async function (e) {
        await objDotNet.invokeMethodAsync('onHidden');
    })

    $(objDom).modal('show');
}

export function close(objDom) {
    $(objDom).modal('hide');
}

export function dispose(objDom) {
    $(objDom).unbind();
}