﻿import $ from 'jquery';

export function processPosition(element, x, y) {
    var menuHeight = $(element).height();
    var menuWidth = $(element).width();

    var newX = x; var newY = y;
    if (y + menuHeight > window.innerHeight) {
        newY = y - menuHeight;
        $(element).css('top', newY);
    }

    if (x + menuWidth > window.innerWidth) {
        newX = x - menuWidth;
        $(element).css('left', newX);
    }
}
