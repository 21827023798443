﻿export function SyncronizeTextAreas(linesTA, codeTA) {
    if (linesTA instanceof HTMLTextAreaElement && codeTA instanceof HTMLTextAreaElement) {
        linesTA.scrollTop = codeTA.scrollTop;
    }
}

export function FillLines(linesTA, codeTA) {
    if (linesTA instanceof HTMLTextAreaElement && codeTA instanceof HTMLTextAreaElement) {
        var endIndex = codeTA.value.split('\n').length;
        linesTA.value = Array.from({ length: endIndex }, (_, i) => (i + 1) + ' ').join('\n');
    }
}

export function InitializeTA(linesTA, codeTA) {
    if (linesTA instanceof HTMLTextAreaElement && codeTA instanceof HTMLTextAreaElement) {
        // PaintLineNumbers(canvas, ta);
        codeTA.onscroll = function (ev) { SyncronizeTextAreas(linesTA, codeTA) };
        codeTA.onmousedown = function (ev) { codeTA.mouseisdown = true; }
        codeTA.onmouseup = function (ev) { codeTA.mouseisdown = false; SyncronizeTextAreas(linesTA, codeTA); };
        codeTA.onmousemove = function (ev) { if (codeTA.mouseisdown) SyncronizeTextAreas(linesTA, codeTA); };
    }
}

export function OnDrop(event, codeTA) {
    console.log("ondrop started " + event)
    console.log("ondrop event is read")
    if (event instanceof DragEvent) {

        console.log(JSON.stringify(event.dataTransfer.files[0].name))
        event.dataTransfer.getData()
        codeTA.value = event.dataTransfer.files[0].text
    }
}