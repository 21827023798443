import 'bootstrap';
import '../scss/app.scss'

var frCloud = {}
var modules = {}

function importAll(r) {
    r.keys().forEach(key => {
        let obj = r(key);

        Object.keys(obj).forEach(method => {
            frCloud[method] = obj[method];
        });
    });
}

importAll(require.context('./frCloud/', true, /^.*\.js$/));

//console.log(window.frCloud = frCloud)

function ImportComponents(r) {
    r.keys().forEach(key => {
        var component = /^.*\/([\w\d]+)\.razor\.js$/.exec(key)[1];
        modules[component] = r(key);
    });
}

frCloud.import = (key) => modules[key];

ImportComponents(require.context('../../Components/', true, /^.*\.razor\.js$/));
ImportComponents(require.context('../../Pages/', true, /^.*\.razor\.js$/));
ImportComponents(require.context('../../UIKit/', true, /^.*\.razor\.js$/));

window.frCloud = frCloud